function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

function getQueryString2(key) {
  var _t = window.location.href.split("?")
  if (_t.length > 1) {
    var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
    var r = _t[1].match(reg);
    if (r != null) return unescape(r[2]); return "";
  }
  return "";
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return `${year}-${month}-${day} ${hour}:${min}`
}
export {
  getQueryString,
  getQueryString2,
  formatDate
}
