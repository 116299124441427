import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import jq from 'jquery'
import './style'
// import './utils/rem.js'
import 'lib-flexible/flexible'
import './mock'
import request from './utils/request'
import compress from './utils/compress'
import {
  Lazyload,
  Toast
} from 'vant'
Vue.use(Lazyload).use(Toast)

// 手动签名插件
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

// Data组件方法赋值
Date.prototype.format = function (fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

Vue.prototype.$moment = moment
Vue.prototype.$axios = request
Vue.prototype.$jq = jq
Vue.prototype.$compress = compress

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
