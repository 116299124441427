import Vue from "vue";
import VueRouter from "vue-router";
import {
	getQueryString,
	getQueryString2
} from "@/utils/utils";
// import BasicLayout from '@/layouts/BasicLayout.vue'
// import Home from '@/pages/tabbar/home/index.vue'

// 解决路由重复跳转
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [{
		path: "/Authority",
		name: "Authority",
		meta: {
			title: "幼儿教育管理平台"
		},
		component: () => import("@/pages/Authority/index.vue")
	},
	{
		path: "/",
		name: "",
		meta: {
			title: "幼儿教育管理平台"
		},
		redirect: "/Authority"
	},
	{
		path: "/AuthorityForCode",
		name: "AuthorityForCode",
		meta: {
			title: "幼儿教育管理平台"
		},
		component: () => import("@/pages/AuthorityForCode/index.vue")
	},
	{
		path: "/AuthorityForSignIn",
		name: "AuthorityForSignIn",
		meta: {
			title: "幼儿教育管理平台"
		},
		component: () => import("@/pages/AuthorityForSignIn/index.vue")
	},
	{
		path: "/ChoseRole",
		name: "ChoseRole",
		meta: {
			title: "选择角色"
		},
		component: () => import("@/pages/Register/ChoseRole/index.vue")
	},
	{
		path: "/ChangeRole",
		name: "ChangeRole",
		meta: {
			title: "选择角色"
		},
		component: () => import("@/pages/Register/ChangeRole/index.vue")
	},
	{
		path: "/Login",
		name: "Login",
		meta: {
			title: "账号登录"
		},
		component: () => import("@/pages/Register/Login/index.vue")
	},
	{
		path: "/ParentRegister",
		name: "ParentRegister",
		meta: {
			title: "注册信息"
		},
		component: () => import("@/pages/Register/ParentRegister/index.vue")
	},
	{
		path: "/RegisterEdit",
		name: "RegisterEdit",
		meta: {
			title: "编辑信息"
		},
		component: () => import("@/pages/Register/RegisterEdit/index.vue")
	},
	{
		path: "/Home",
		name: "Home",
		meta: {
			title: "首页"
		},
		component: () => import("@/pages/TabBar/Home/index.vue")
	},
	{
		path: "/HomeEmpty",
		name: "HomeEmpty",
		meta: {
			title: "首页"
		},
		component: () => import("@/pages/TabBar/HomeEmpty/index.vue")
	},
	{
		path: "/Home/dailyRecord",
		name: "DailyRecord",
		meta: {
			title: "点滴记录"
		},
		component: () => import("@/pages/TabBar/Home/dailyRecord/index.vue")
	},
	{
		path: "/growthManualList",
		name: "GrowthManualList",
		meta: {
			title: "宝贝成长册"
		},
		component: () => import("@/pages/TabBar/Home/growthManualList/index.vue")
	},
	// title 为宝贝姓名
	{
		path: "/growthManualList/childenDetails",
		name: "ChildenDetails",
		component: () => import("@/pages/TabBar/Home/growthManualList/childenDetails/index.vue")
	},
	// title 为教师或家长寄语
	{
		path: "/evalute",
		name: "Evalute",
		component: () => import("@/pages/TabBar/Home/evalute/index.vue")
	},
	{
		path: "/badgeRecord",
		name: "Evalute",
		meta: {
			title: "徽章记录"
		},
		component: () => import("@/pages/TabBar/Home/badgeRecord/index.vue")
	},
	{
		path: "/FamilyHome",
		name: "FamilyHome",
		meta: {
			title: "家园"
		},
		component: () => import("@/pages/TabBar/FamilyHome/index.vue")
	},
	{
		path: '/MealCost',
		name: 'MealCost',
		meta: {
			title: '宝贝伙食费'
		},
		component: () => import('@/pages/TabBar/Home/mealCost/index.vue')
	},
	{
		path: "/parentAttendance",
		name: "ParentAttendance",
		meta: {
			title: "出勤统计"
		},
		component: () => import("@/pages/TabBar/Home/parentAttendance/index.vue")
	},
	{
		path: "/teacherAttendance",
		name: "TeacherAttendance",
		meta: {
			title: "出勤统计"
		},
		component: () => import("@/pages/TabBar/Home/teacherAttendance/index.vue")
	},
	{
		path: "/ClassRoomView",
		name: "ClassRoomView",
		meta: {
			title: "班级圈详情"
		},
		component: () =>
			import("@/pages/TabBar/FamilyHome/ClassRoom/View/index.vue")
	},
	{
		path: "/ClassRoomEdit",
		name: "ClassRoomEdit",
		meta: {
			title: "班级圈新增"
		},
		component: () =>
			import("@/pages/TabBar/FamilyHome/ClassRoom/Edit/index.vue")
	},
	{
		path: "/ClassRoomUnread",
		name: "ClassRoomUnread",
		meta: {
			title: "已读情况"
		},
		component: () =>
			import("@/pages/TabBar/FamilyHome/ClassRoom/Unread/index.vue")
	},
	{
		path: "/FolderView",
		name: "FolderView",
		meta: {
			title: "文件夹详情"
		},
		component: () => import("@/pages/Folder/View/index.vue")
	},
	{
		path: "/FolderEdit",
		name: "FolderEdit",
		meta: {
			title: "文件夹新增"
		},
		component: () => import("@/pages/Folder/Edit/index.vue")
	},
	{
		path: "/FolderFileAdd",
		name: "FolderFileAdd",
		meta: {
			title: "文件新增"
		},
		component: () =>
			import("@/pages/Folder/AddFile/index.vue")
	},
	{
		path: "/FolderFileUpload",
		name: "FolderFileUpload",
		meta: {
			title: "上传情况"
		},
		component: () =>
			import("@/pages/Folder/UnUpload/index.vue")
	},
	{
		path: "/Contact",
		name: "Contact",
		meta: {
			title: "联系册"
		},
		component: () => import("@/pages/TabBar/Contact/index.vue")
	},
	{
		path: "/My",
		name: "My",
		meta: {
			title: "我的"
		},
		component: () => import("@/pages/TabBar/My/index.vue")
	},
	{
		path: "/ParentMy",
		name: "ParentMy",
		meta: {
			title: "我的"
		},
		component: () => import("@/pages/TabBar/My/Parent/index.vue")
	},
	{
		path: "/TeacherMy",
		name: "TeacherMy",
		meta: {
			title: "我的"
		},
		component: () => import("@/pages/TabBar/My/Teacher/index.vue")
	},
	{
		path: "/AddChild",
		name: "AddChild",
		meta: {
			title: "添加宝贝"
		},
		component: () => import("@/pages/TabBar/My/Parent/addChild.vue")
	},
	{
		path: "/SchoolInfo",
		name: "SchoolInfo",
		meta: {
			title: "幼儿园信息"
		},
		component: () => import("@/pages/School/SchoolInfo/index.vue")
	},
	{
		path: "/SchoolEdit",
		name: "SchoolEdit",
		meta: {
			title: "幼儿园信息-编辑"
		},
		component: () => import("@/pages/School/SchoolEdit/index.vue")
	},
	// {
	// 	path: "/RestView",
	// 	name: "RestView",
	// 	meta: {
	// 		title: "作息安排"
	// 	},
	// 	component: () => import("@/pages/Rest/RestView/index.vue")
	// },
	// {
	// 	path: "/RestViewAll",
	// 	name: "RestViewAll",
	// 	meta: {
	// 		title: "作息安排"
	// 	},
	// 	component: () => import("@/pages/Rest/RestViewAll/index.vue")
	// },
	// {
	// 	path: "/DisinfectionList",
	// 	name: "DisinfectionList",
	// 	meta: {
	// 		title: "园区消毒"
	// 	},
	// 	component: () => import("@/pages/Disinfection/DisinfectionList/index.vue")
	// },
	// {
	// 	path: "/DisinfectionAdd",
	// 	name: "DisinfectionAdd",
	// 	meta: {
	// 		title: "园区消毒-添加"
	// 	},
	// 	component: () => import("@/pages/Disinfection/DisinfectionAdd/index.vue")
	// },
	// {
	// 	path: "/DisinfectionEdit",
	// 	name: "DisinfectionEdit",
	// 	meta: {
	// 		title: "园区消毒-编辑"
	// 	},
	// 	component: () => import("@/pages/Disinfection/DisinfectionEdit/index.vue")
	// },
	// {
	// 	path: "/DisinfectionView",
	// 	name: "DisinfectionView",
	// 	meta: {
	// 		title: "园区消毒-详情"
	// 	},
	// 	component: () => import("@/pages/Disinfection/DisinfectionView/index.vue")
	// },
	// {
	// 	path: "/MorningCheckList",
	// 	name: "MorningCheckList",
	// 	meta: {
	// 		title: "晨午检"
	// 	},
	// 	component: () => import("@/pages/MorningCheck/MorningCheckList/index.vue")
	// },
	// {
	// 	path: "/MorningCheckViewEdit",
	// 	name: "MorningCheckViewEdit",
	// 	meta: {
	// 		title: "晨午检-详情"
	// 	},
	// 	component: () => import("@/pages/MorningCheck/MorningCheckViewEdit/index.vue")
	// },
	// {
	// 	path: "/MorningCheckViewAdd",
	// 	name: "MorningCheckViewAdd",
	// 	meta: {
	// 		title: "晨午检-新增"
	// 	},
	// 	component: () => import("@/pages/MorningCheck/MorningCheckViewAdd/index.vue")
	// },
	{
		path: "/RecipesView",
		name: "RecipesView",
		meta: {
			title: "膳食营养"
		},
		component: () => import("@/pages/Recipes/RecipesView/index.vue")
	},
	{
		path: "/RecipesViewAll",
		name: "RecipesViewAll",
		meta: {
			title: "卫生保健"
		},
		component: () => import("@/pages/Recipes/RecipesViewAll/index.vue")
	},
	{
		path: "/RecipesWeek",
		name: "RecipesWeek",
		meta: {
			title: "一周食谱"
		},
		component: () => import("@/pages/Recipes/RecipesWeek/index.vue")
	},
	{
		path: "/KnowledgeList",
		name: "KnowledgeList",
		meta: {
			title: "保健知识"
		},
		component: () => import("@/pages/Recipes/KnowledgeList/index.vue")
	},
	{
		path: "/KnowledgeView",
		name: "KnowledgeView",
		meta: {
			title: "保健知识-查看"
		},
		component: () => import("@/pages/Recipes/KnowledgeView/index.vue")
	},
	{
		path: "/ParentSchoolMain",
		name: "ParentSchoolMain",
		meta: {
			title: "家长学校"
		},
		component: () => import("@/pages/ParentSchool/ParentSchoolMain/index.vue")
	},
	{
		path: "/ParentSchoolList",
		name: "ParentSchoolList",
		meta: {
			title: "家长学校"
		},
		component: () => import("@/pages/ParentSchool/ParentSchoolList/index.vue")
	},
	{
		path: "/ParentSchoolView",
		name: "ParentSchoolView",
		meta: {
			title: "家长学校-详情"
		},
		component: () => import("@/pages/ParentSchool/ParentSchoolView/index.vue")
	},
	{
		path: "/MailboxForParentList",
		name: "MailboxForParentList",
		meta: {
			title: "家长信箱"
		},
		component: () => import("@/pages/ParentMailbox/MailboxForParentList/index.vue")
	},
	{
		path: "/MailboxForTeacherList",
		name: "MailboxForTeacherList",
		meta: {
			title: "家长信箱"
		},
		component: () => import("@/pages/ParentMailbox/MailboxForTeacherList/index.vue")
	},
	{
		path: "/MailboxAdd",
		name: "MailboxAdd",
		meta: {
			title: "家长信箱新增"
		},
		component: () => import("@/pages/ParentMailbox/MailboxAdd/index.vue")
	},
	{
		path: "/MailboxView",
		name: "MailboxView",
		meta: {
			title: "家长信箱-详情"
		},
		component: () => import("@/pages/ParentMailbox/MailboxView/index.vue")
	},
	{
		path: "/RecipesEdit",
		name: "RecipesEdit",
		meta: {
			title: "膳食营养"
		},
		component: () => import("@/pages/Recipes/RecipesEdit/Index.vue")
	},
	// {
	// 	path: "/MedicationListParent",
	// 	name: "MedicationListParent",
	// 	meta: {
	// 		title: "委托用药"
	// 	},
	// 	component: () => import("@/pages/Medication/MedicationListParent/index.vue")
	// },
	// {
	// 	path: "/MedicationListTeacher",
	// 	name: "MedicationListTeacher",
	// 	meta: {
	// 		title: "委托用药"
	// 	},
	// 	component: () =>
	// 		import("@/pages/Medication/MedicationListTeacher/index.vue")
	// },
	// {
	// 	path: "/MedicationAdd",
	// 	name: "MedicationAdd",
	// 	meta: {
	// 		title: "委托用药-新增"
	// 	},
	// 	component: () => import("@/pages/Medication/MedicationAdd/index.vue")
	// },
	// {
	// 	path: "/MedicationViewParent",
	// 	name: "MedicationViewParent",
	// 	meta: {
	// 		title: "委托用药-详情"
	// 	},
	// 	component: () => import("@/pages/Medication/MedicationListParent/view.vue")
	// },
	// {
	// 	path: "/MedicationViewTeacher",
	// 	name: "MedicationViewTeacher",
	// 	meta: {
	// 		title: "委托用药-详情"
	// 	},
	// 	component: () => import("@/pages/Medication/MedicationListTeacher/view.vue")
	// },
	// {
	// 	path: "/RepairManage",
	// 	name: "RepairManage",
	// 	meta: {
	// 		title: "零星维修"
	// 	},
	// 	component: () => import("@/pages/RepairManage/index.vue")
	// },
	// {
	// 	path: "/RepairRegisterView",
	// 	name: "RepairRegisterView",
	// 	meta: {
	// 		title: "零星维修-详情"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManage/RepairRegister/View/index.vue")
	// },
	// {
	// 	path: "/RepairRegisterEdit",
	// 	name: "RepairRegisterEdit",
	// 	meta: {
	// 		title: "零星维修-新增"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManage/RepairRegister/Edit/index.vue")
	// },
	// {
	// 	path: "/RepairHandelView",
	// 	name: "RepairHandelView",
	// 	meta: {
	// 		title: "零星维修-详情"
	// 	},
	// 	component: () => import("@/pages/RepairManage/RepairHandel/View/index.vue")
	// },
	// {
	// 	path: "/RepairHandelEdit",
	// 	name: "RepairHandelEdit",
	// 	meta: {
	// 		title: "零星维修-编辑"
	// 	},
	// 	component: () => import("@/pages/RepairManage/RepairHandel/Edit/index.vue")
	// },
	// {
	// 	path: "/RepairManageZc",
	// 	name: "RepairManageZc",
	// 	meta: {
	// 		title: "资产报修"
	// 	},
	// 	component: () => import("@/pages/RepairManageZc/index.vue")
	// },
	// {
	// 	path: "/RepairRegisterViewZc",
	// 	name: "RepairRegisterViewZc",
	// 	meta: {
	// 		title: "资产报修-详情"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManageZc/RepairRegister/View/index.vue")
	// },
	// {
	// 	path: "/RepairRegisterEditZc",
	// 	name: "RepairRegisterEditZc",
	// 	meta: {
	// 		title: "资产报修-新增"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManageZc/RepairRegister/Edit/index.vue")
	// },
	// {
	// 	path: "/RepairHandelViewZc",
	// 	name: "RepairHandelViewZc",
	// 	meta: {
	// 		title: "资产报修-详情"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManageZc/RepairHandel/View/index.vue")
	// },
	// {
	// 	path: "/RepairHandelEditZc",
	// 	name: "RepairHandelEditZc",
	// 	meta: {
	// 		title: "资产报修-编辑"
	// 	},
	// 	component: () =>
	// 		import("@/pages/RepairManageZc/RepairHandel/Edit/index.vue")
	// },
	// {
	// 	path: "/RepairQcodeZc",
	// 	name: "RepairQcodeZc",
	// 	meta: {
	// 		title: "资产报修"
	// 	},
	// 	component: () => import("@/pages/RepairManageZc/Qcode/View/index.vue")
	// },
	// {
	// 	path: "/RepairQcodeAddZc",
	// 	name: "RepairQcodeAddZc",
	// 	meta: {
	// 		title: "资产报修-新增"
	// 	},
	// 	component: () => import("@/pages/RepairManageZc/Qcode/Edit/index.vue")
	// },
	{
		path: "/OverTime",
		name: "OverTime",
		meta: {
			title: "加班管理",
			keepAlive:true
		},
		component: () => import("@/pages/OverTime/index.vue")
	},
	{
		path: "/OverTimeMyAdd",
		name: "OverTimeMyAdd",
		meta: {
			title: "加班管理-新增"
		},
		component: () => import("@/pages/OverTime/My/Add/index.vue")
	},
	{
		path: "/OverTimeMyEdit",
		name: "OverTimeMyEdit",
		meta: {
			title: "加班申请-编辑"
		},
		component: () => import("@/pages/OverTime/My/Edit/index.vue")
	},
	{
		path: "/OverTimeMyView",
		name: "OverTimeMyView",
		meta: {
			title: "加班申请-详情"
		},
		component: () => import("@/pages/OverTime/My/View/index.vue")
	},
	{
		path: "/LeaveTeacher",
		name: "LeaveTeacher",
		meta: {
			title: "教师请假",
			keepAlive:true
		},
		component: () => import("@/pages/LeaveTeacher/index.vue")
	},
	{
		path: "/LeaveTeacherMyAdd",
		name: "LeaveTeacherMyAdd",
		meta: {
			title: "教师请假-新增"
		},
		component: () => import("@/pages/LeaveTeacher/My/Add/index.vue")
	},
	{
		path: "/LeaveTeacherMyEdit",
		name: "LeaveTeacherMyEdit",
		meta: {
			title: "教师请假-编辑"
		},
		component: () => import("@/pages/LeaveTeacher/My/Edit/index.vue")
	},
	{
		path: "/LeaveTeacherMyView",
		name: "LeaveTeacherMyView",
		meta: {
			title: "教师请假-详情"
		},
		component: () => import("@/pages/LeaveTeacher/My/View/index.vue")
	},
	{
		path: "/LeaveTeacherMyAudit",
		name: "LeaveTeacherMyAudit",
		meta: {
			title: "教师请假-审核"
		},
		component: () => import("@/pages/LeaveTeacher/Audit/Edit/index.vue")
	},
	{
		path: "/LeaveBoby",
		name: "LeaveBoby",
		meta: {
			title: "请假记录"
		},
		component: () => import("@/pages/LeaveBoby/index.vue")
	},
	{
		path: "/LeaveBobyMyAdd",
		name: "LeaveBobyMyAdd",
		meta: {
			title: "宝贝请假-新增"
		},
		component: () => import("@/pages/LeaveBoby/My/Add/index.vue")
	},
	{
		path: "/LeaveBobyMyEdit",
		name: "LeaveBobyMyEdit",
		meta: {
			title: "宝贝请假-编辑"
		},
		component: () => import("@/pages/LeaveBoby/My/Edit/index.vue")
	},
	{
		path: "/LeaveBobyMyView",
		name: "LeaveBobyMyView",
		meta: {
			title: "宝贝请假-详情"
		},
		component: () => import("@/pages/LeaveBoby/My/View/index.vue")
	},
	{
		path: "/LeaveBobyAudit",
		name: "LeaveBobyAudit",
		meta: {
			title: "宝贝请假"
		},
		component: () => import("@/pages/LeaveBoby/Audit/List/index.vue")
	},
	{
		path: "/LeaveBobyAuditEdit",
		name: "LeaveBobyAuditEdit",
		meta: {
			title: "宝贝请假-审核"
		},
		component: () => import("@/pages/LeaveBoby/Audit/Edit/index.vue")
	},
	{
		path: "/LeaveBobyAuditView",
		name: "LeaveBobyAuditView",
		meta: {
			title: "宝贝请假-详情"
		},
		component: () => import("@/pages/LeaveBoby/Audit/View/index.vue")
	},
	{
		path: "/LeaveBoby/writeSign",
		name: "WriteSign",
		meta: {
			title: "手写签名"
		},
		component: () => import("@/pages/LeaveBoby/writeSign/index.vue")
	},
	{
		path: "/GrowthBook",
		name: "GrowthBook",
		meta: {
			title: "成长册"
		},
		component: () => import("@/pages/GrowthBook/List/index.vue")
	},
	{
		path: "/GrowthBookAdd",
		name: "GrowthBookAdd",
		meta: {
			title: "成长册-新增"
		},
		component: () => import("@/pages/GrowthBook/Add/index.vue")
	},
	{
		path: "/GrowthBookView",
		name: "GrowthBookView",
		meta: {
			title: "成长册-详情"
		},
		component: () => import("@/pages/GrowthBook/View/index.vue")
	},
	{
		path: "/BabyCircleView",
		name: "BabyCircleView",
		meta: {
			title: "宝贝圈-详情"
		},
		component: () =>
			import("@/pages/TabBar/FamilyHome/BabyCircle/View/index.vue")
	},
	{
		path: "/NoticeList",
		name: "NoticeList",
		meta: {
			title: "通知公告"
		},
		component: () => import("@/pages/Notice/NoticeList/index.vue")
	},
	{
		path: "/NoticeView",
		name: "NoticeView",
		meta: {
			title: "通知公告-详情"
		},
		component: () => import("@/pages/Notice/NoticeView/index.vue")
	},
	{
		path: '/AuditSchedule',
		name: 'AuditSchedule',
		meta: {
			title: '审核事项'
		},
		component: () => import('@/pages/AuditSchedule/index.vue')
	},
	{
		path: '/LeaveChildrenView',
		name: 'LeaveChildrenView',
		meta: {
			title: '请假审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/LeaveChildren/View/index.vue')
	},
	{
		path: '/LeaveTeacherView',
		name: 'LeaveTeacherView',
		meta: {
			title: '请假审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/LeaveChildren/LeaveTeacherView/index.vue')
	},
	{
		path: '/OverTimeView',
		name: 'OverTimeView',
		meta: {
			title: '加班审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/OverTime/View/index.vue')
	},
	{
		path: '/ProclamationView',
		name: 'ProclamationView',
		meta: {
			title: '公告审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/Proclamation/View/index.vue')
	},
	{
		path: '/ContentView',
		name: 'ContentView',
		meta: {
			title: '内容审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/Content/View/index.vue')
	},
	{
		path: '/GroupView',
		name: 'GroupView',
		meta: {
			title: '集团介绍-详情'
		},
		component: () => import('@/pages/GroupIntroduction/View/index.vue')
	},
	{
		path: '/NewsNoticeMain',
		name: 'NewsNoticeMain',
		meta: {
			title: '新闻资讯-主页'
		},
		component: () => import('@/pages/NewsNotice/NewsNoticeMain/index.vue')
	},
	{
		path: '/NewsNoticeList',
		name: 'NewsNoticeList',
		meta: {
			title: '新闻资讯-列表'
		},
		component: () => import('@/pages/NewsNotice/NewsNoticeList/index.vue')
	},
	{
		path: '/NewsNoticeView',
		name: 'NewsNoticeView',
		meta: {
			title: '新闻资讯-详情'
		},
		component: () => import('@/pages/NewsNotice/NewsNoticeView/index.vue')
	},
	{
		path: '/CloudClassroomMain',
		name: 'CloudClassroomMain',
		meta: {
			title: '云课堂'
		},
		component: () => import('@/pages/CloudClassroom/CloudClassroomMain/index.vue')
	},
	{
		path: '/CloudClassroomList',
		name: 'CloudClassroomList',
		meta: {
			title: '云课堂'
		},
		component: () => import('@/pages/CloudClassroom/CloudClassroomList/index.vue')
	},
	{
		path: '/CloudClassroomView',
		name: 'CloudClassroomView',
		meta: {
			title: '云课堂-详情'
		},
		component: () => import('@/pages/CloudClassroom/CloudClassroomView/index.vue')
	},
	{
		path: '/ChildrenLeave',
		name: 'ChildrenLeave',
		meta: {
			title: '宝贝请假'
		},
		component: () => import('@/pages/LeaveTeacher/ChildrenLeave/index.vue')
	},
  ,
	{
		path: '/NoticeMain',
		name: 'NoticeMain',
		meta: {
			title: '通知公告'
		},
		component: () => import('@/pages/Notice/NoticeMain/index.vue')
	},{
		path: '/Folder',
		name: 'Folder',
		meta: {
			title: '报告上传'
		},
		component: () => import('@/pages/Folder/List/index.vue')
	},
	{
		path: "/ClassCircle",
		name: "ClassCircle",
		meta: {
			title: "班级圈"
		},
		component: () => import("@/pages/ClassCircle/index.vue")
	},
	{
		path: "/ReportFileAdd",
		name: "ReportFileAdd",
		meta: {
			title: "报告新增"
		},
		component: () =>
			import("@/pages/Folder/AddReportFile/index.vue")
	},
	{
		path: '/ChildrenInfoView',
		name: 'ChildrenInfoView',
		meta: {
			title: '注册审核-详情'
		},
		component: () => import('@/pages/AuditSchedule/ChildrenInfo/View/index.vue')
	},
	{
		path: "/TeacherMyAttendance",
		name: "TeacherMyAttendance",
		meta: {
			title: "我的出勤",
			keepAlive:true
		},
		component: () => import("@/pages/TabBar/Home/teacherMyAttendance/index.vue")
	},
	{
		path: "/ChildrenAttendance",
		name: "ChildrenAttendance",
		meta: {
			title: "宝贝出勤",
			keepAlive:true
		},
		component: () => import("@/pages/TabBar/Home/childrenAttendance/index.vue")
	},
	{
		path: "/Review",
		name: "Review",
		meta: {
			title: "预览",
			keepAlive:true
		},
		component: () => import("@/pages/Review/index.vue")
	},
];

const router = new VueRouter({
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
		if (document.title !== "幼儿教育管理平台") {
			// 需要把上一个页面地址存起来
			window.localStorage.setItem("lastPage", document.URL);
			if (to.path && to.path !== "null") {
				window.localStorage.setItem("lastPath", to.path);
			}
		}
	}
	// 判断是否已经缓存了code，否则发起授权
	const code = getQueryString("code");
	if (code && code !== "null") {
		window.localStorage.setItem("code", code);
	}
	// 是否通过扫码进入的
	const qcode = getQueryString2("qcode");
	const TOKEN = window.localStorage.getItem("TOKEN");
	const wxuserInfo = window.localStorage.getItem("wxuserInfo");
	if (!TOKEN && document.title !== "幼儿教育管理平台" && !wxuserInfo && !qcode) {
		window.localStorage.removeItem("code");
		router.push({
			path: "/Authority"
		});
		// window.location.href = 'http://localhost:8075/#/' // 'http://tjb.whsedu.cn/'
	} else {
		next();
	}
});
export default router;
